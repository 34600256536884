import { useParams } from 'react-router-dom';

import api from '~/services/api';
import { ILocalAdmin } from '~/services/api/localAdmin/types';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

const useLocalAdminConfirmProps = (
  localAdmin: ILocalAdmin,
  refetchUsers: () => void,
): { confirmText: string; description: string; method: () => void } => {
  const { id } = useParams<{ id: string }>();

  const status = (localAdmin || {})?.status as string;

  const {
    onUnAssignLocalAdmin,
    onDeactivateLocalAdmin,
    onActivateLocalAdmin,
    onUnlockLocalAdmin,
    onArchiveLocalAdmin,
  } = useStoreActions(actions => actions.localAdmin);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const payload = { clinicId: id, localAdminId: localAdmin?.id };
  if (status === 'Active')
    return {
      confirmText: 'Deactivate',
      description: 'Are you sure you want to deactivate this user?',
      method: () => onDeactivateLocalAdmin(payload),
    };

  if (status === 'Deactivated')
    return {
      confirmText: 'Activate',
      description: 'Are you sure you want to activate this user?',
      method: () => onActivateLocalAdmin(payload),
    };

  if (status === 'Locked')
    return {
      confirmText: 'Unlock',
      description: 'Are you sure you want to unlock this user?',
      method: () => onUnlockLocalAdmin(payload),
    };

  if (status === 'Assigned')
    return {
      confirmText: 'Unassign',
      description: 'Are you sure you want to unassign this user?',
      method: async () => {
        try {
          await onUnAssignLocalAdmin(payload);
          refetchUsers();
          showNotify({ message: `User successfully unassigned` });
        } catch (e) {
          showError(extractErrorMessage(e));
        }
      },
    };
  if (status === 'VerifyingEmail')
    return {
      confirmText: 'Archive',
      description: 'Are you sure you want to archive this user?',
      method: async () => {
        try {
          await onArchiveLocalAdmin(payload);
          refetchUsers();
          showNotify({ message: `User successfully archived` });
        } catch (e) {
          showError(extractErrorMessage(e));
        }
      },
    };
  return {
    confirmText: 'Resend',
    description: 'Are you sure you want to resend invitation?',
    method: () => api.localAdmin.resendInvitation(id, localAdmin?.id),
  };
};

export default useLocalAdminConfirmProps;
