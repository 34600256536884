import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import { ReactElement, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Status from '~/ui/components/common/Status';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import getButtonText from '~/utils/text/getButtonText';

import { IProgramAssistantForm } from '~/services/api/programAssistant/types';
import { IParams, Sorting } from '~/services/api/types';
import { red } from '~/ui/constants/colors';
import { multipleStatusesOptions, sortingOptions } from '~/ui/constants/sortingOptions';
import supervisorStatuses from '../../constants/supervisorStatusesToUnlock';

import styles from './Table.module.scss';

const defaultValues = {
  name: '',
  sorting: Sorting.ByName,
  status: 0,
};

interface IProps {
  setProgramAssistant: (arg: IProgramAssistantForm) => void;
}

const ProgramAssistantTable = ({ setProgramAssistant }: IProps): ReactElement => {
  const formMethods = useForm({ defaultValues });
  const {
    register,
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = formMethods;
  const watchValues = watch();

  const { list, pagination } = useStoreState(state => state.programAssistant);
  const { clinic } = useStoreState(state => state.user.current);
  const clinicId = clinic?.id ? String(clinic.id) : undefined;
  const onGetProgramAssistants = useStoreActions(
    actions => actions.programAssistant.onGetProgramAssistants,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const handlePagination = (pageNumber: number, pageSize: number) => {
    onGetProgramAssistants({
      clinicId,
      params: { pageNumber, pageSize, ...watchValues },
    });
  };

  const onSubmit = (params: IParams) => {
    onGetProgramAssistants({ clinicId, params: { ...pagination, ...params } });
  };

  const onMount = useCallback(async () => {
    try {
      await onGetProgramAssistants({ clinicId, params: { pageNumber: 1, pageSize: 5 } });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [clinicId, onGetProgramAssistants, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <TableContainer>
      <Box sx={{ p: 2 }}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <Input
                  startAdornment={<SearchIcon color="primary" />}
                  placeholder="Search Program Assistant"
                  register={register}
                  name="name"
                  errors={errors}
                />
              </Grid>
              <Grid item sm={2}>
                <Select
                  label="Sort By"
                  control={control}
                  name="sorting"
                  errors={errors}
                  options={sortingOptions}
                  color={red}
                  hideSelectedOptions={false}
                />
              </Grid>
              <Grid item sm={2}>
                <Select
                  label="Filter By Status"
                  control={control}
                  name="status"
                  errors={errors}
                  options={multipleStatusesOptions}
                  color={red}
                  hideSelectedOptions={false}
                />
              </Grid>
              <AutoSubmit debounce={1000} initialValues={defaultValues} onSubmit={onSubmit} />
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <MUITable>
        <TableHead>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item: any) => (
            <TableRow key={item.id}>
              <TableCell>{item.firstName}</TableCell>
              <TableCell>{item.lastName}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>
                <Status status={item.status} />
              </TableCell>
              <TableCell>
                {supervisorStatuses.includes(item.status) && (
                  <button
                    type="button"
                    className={styles.actionButton}
                    onClick={() => {
                      setProgramAssistant(item);
                    }}
                  >
                    {getButtonText(item.status)}
                  </button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
      <TablePagination
        component="div"
        count={pagination.total}
        onPageChange={(_, pageNumber) => {
          handlePagination(pageNumber + 1, pagination.pageSize);
        }}
        onRowsPerPageChange={e => {
          handlePagination(1, Number(e.target.value));
        }}
        page={pagination.pageNumber - 1}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
  );
};

export default ProgramAssistantTable;
