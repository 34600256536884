import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { ReactElement, ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '~/ui/components/common/Button';
import ListItem from '~/ui/components/common/ListItem';
import Input from '~/ui/components/inputs/Input';

import lowercaseFirst from '~/utils/text/lowercaseFirst';
import removeSpaces from '~/utils/text/removeSpaces';
import validationSchema from './validate';

import { ReactComponent as EditIcon } from '~/ui/assets/images/edit.svg';

import styles from './EditableListItem.module.scss';

interface IEditableListItemProps {
  label: string;
  content: ReactNode;
  disabled?: boolean;
  onSave: (value: { [key: string]: string }) => void;
}

const EditableListItem = ({
  label,
  content,
  disabled = false,
  onSave,
}: IEditableListItemProps): ReactElement => {
  const [editing, setEditing] = useState(false);

  const filedName = lowercaseFirst(removeSpaces(label));

  const {
    register,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema(filedName)),
    defaultValues: { [`${filedName}`]: content },
  });

  const fields = watch() as { [key: string]: string };

  const handleSave = () => {
    const value = fields[filedName];
    onSave({ [`${filedName}`]: value });
    setEditing(false);
  };
  const handleEdit = () => {
    setEditing(true);
  };

  const renderContent = () => (
    <Box className={styles.row}>
      {editing ? (
        <Box className={styles.row}>
          <Input register={register} errors={errors} name={filedName} label={label} />
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      ) : (
        <Box className={styles.row}>
          {content || <span> </span>}
          <IconButton onClick={handleEdit} size="small" disabled={disabled}>
            <EditIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );

  return (
    <ListItem
      label={label}
      content={renderContent()}
      moreLabelWidth
      className={styles.contentContainer}
    />
  );
};

export default EditableListItem;
