const lowercaseFirst = (text: string): string => {
  if (typeof text !== 'string') {
    return '';
  }

  if (text.length === 0) {
    return text;
  }

  return text[0].toLowerCase() + text.slice(1);
};

export default lowercaseFirst;
