import { IDictionary } from '../dictionaries/types';
import { IAddress, IAddressExtended, ICommonClientDetails } from '../types';

type INotificationOn = 'SameDay' | 'OneDayBefore' | 'OneWeekBefore' | 'OneMonthBefore';
type ITriggerFrequency = 'Yearly' | 'Monthly' | 'Weekly' | 'OncePerTwoWeeks';
type IMedicationFrequency = 'Yearly' | 'Monthly' | 'Weekly' | 'OncePerTwoWeeks';
export type IClientTriggerType = 'Recurring' | 'NonRecurring';

export enum TriggerType {
  Recurring = 'Recurring',
  NoNRecurring = 'NoNRecurring',
}

export enum PaperWorkStatus {
  PromptAcknowledged = 'PromptAcknowledged',
  NotReceived = 'NotReceived',
  NotSubmitted = 'NotSubmitted',
  Submitted = 'Submitted',
  Expired = 'ExpiredAcknowledged',
}

export interface IRequestParams {
  clinicId: string;
  teamId: string;
  clientId: string;
  id?: string;
}

export interface StatusChangeLog {
  date: string;
  user: {
    id: number;
    name: string;
  };
  delivered: boolean;
}

export interface IClientDeEscalationTechnique extends ICommonClientDetails {
  deEscalationTechnique: IDictionary;
}

interface ISeverityLevel extends IDictionary {
  highlighted: boolean;
}

export interface IClientAllergySeverityLevel {
  severityLevel?: ISeverityLevel | null;
}

export interface IClientAllergy extends ICommonClientDetails, IClientAllergySeverityLevel {
  allergy: IDictionary;
}

export interface IClientDiagnosis extends ICommonClientDetails {
  diagnosis: IDictionary;
  status: IDictionary;
}

export interface IClientMedication extends ICommonClientDetails {
  dose: string;
  medication: IDictionary;
  injectable: boolean;
  startDate?: string;
  endDate?: string;
  outsideFacility?: boolean;
}

export interface IClientPhone extends Omit<ICommonClientDetails, 'note'> {
  number: string;
  type: IDictionary;
  isPrimary: boolean;
  isArchived: boolean;
}

export interface IClientTrigger extends ICommonClientDetails {
  trigger: IDictionary;
  type: IClientTriggerType;
}

export interface IClientDeEscalationRequest {
  id: number;
  note: string;
}

export interface IClientDiagnosisRequest {
  id: number;
  note: string;
  statusId?: number;
}

export interface IClientDeEscalationResponse extends ICommonClientDetails {
  deEscalationTechnique: IDictionary;
}

export interface IClientAllergyRequest {
  id: number;
  note: string;
  severityLevelId?: number;
}

export interface IClientAllergyResponse extends ICommonClientDetails {
  allergy: IDictionary;
  severityLevel?: ISeverityLevel;
}

export type IClientSecondaryDiagnosisRequest = IClientDiagnosisRequest;
export type IClientSecondaryDiagnosisResponse = IClientDiagnosis;

export interface IClientMedicationRequest extends IClientDeEscalationRequest {
  dose: string;
  injectable: boolean;
  outsideFacility?: boolean;
  startDate?: string;
  endDate?: string;
}

export interface IClientMedicationDeliveryRequest extends IClientDeEscalationRequest {
  notDelivered: boolean;
  outsideFacility: boolean;
  date: string;
  teamMemberId: number;
  medicationIds: number[];
  clientId: number;
  nextDueDate: string;
  medicationId?: number;
  nonDeliveryReasonId?: number;
  lastInjection?: boolean;
  archiveMedication?: boolean;
  hasAlerts?: boolean;
  statusChangeLog?: StatusChangeLog;
  notificationOn?: string;
  notificationTime?: any;
  teamMembersToNotify?: number[];
}

export interface IClientMedicationResponse extends ICommonClientDetails {
  medication: IDictionary;
  dose: string;
  startDate?: string;
  endDate?: string;
  frequency?: IMedicationFrequency;
  hasAlerts?: boolean;
  injectable?: boolean;
  outsideFacility?: boolean;
  notificationOn?: INotificationOn;
  teamMembersToNotify?: ITeamMemberToNotify[];
  editInformation?: {
    name: string;
    date: string;
  };
  medicationStartDate?: string;
}

export interface IClientMedicationDeliveryResponse extends ICommonClientDetails {
  client: IDictionary;
  medication: IDictionary & { injectable: boolean; isArchived: boolean; lastInjection: boolean };
  medicationIds: number[];
  teamMember: IDictionary;
  frequency: IMedicationFrequency;
  date: string;
  notDelivered: boolean;
  outsideFacility: boolean;
  nextDueDate: string;
  editInformation: {
    name: string;
    date: string;
  };
  nonDeliveryReason?: IDictionary;
  lastInjection?: boolean;
  hasAlerts?: boolean;
  statusChangeLog?: StatusChangeLog;
  notificationOn?: string;
  notificationTime?: string;
  teamMembersToNotify?: IDictionary[];
}

export interface IClientTriggerRequest {
  frequency?: string;
  hasAlerts: boolean;
  note: string;
  notificationOn?: string;
  teamMembersToNotify?: number[];
  triggerDate?: string;
  triggerId: number;
  type: IClientTriggerType;
}

export interface IClientTriggerResponse {
  id: number;
  trigger: IDictionary;
  note: string;
  type: IClientTriggerType;
  hasAlerts: boolean;
  frequency: ITriggerFrequency;
  triggerDate: string;
  notificationOn: INotificationOn;
  editInformation: {
    name: string;
    date: string;
  };
  teamMembersToNotify: ITeamMemberToNotify[];
}

interface IClientTreatmentPlanObjectiveRequest {
  text: string;
  id: number;
}

interface IClientTreatmentPlanObjective {
  id: number;
  text: string;
  reached: boolean;
  isArchived: boolean;
}

interface IClientTreatmentPlanGoalRequest {
  text: string;
  objectives: IClientTreatmentPlanObjectiveRequest[];
  id: number;
}

export interface IClientTreatmentPlanGoal {
  id: number;
  text: string;
  reached: boolean;
  objectives: IClientTreatmentPlanObjective[];
  isArchived: boolean;
}

export interface IClientTreatmentPlanRequest {
  caseManagerId: number;
  effectiveDate: string;
  nextReviewDate: string;
  goals: IClientTreatmentPlanGoalRequest[];
  setActive: boolean;
  typeId?: number | null;
}

export interface IClientTreatmentPlanResponse {
  id: number;
  caseManagerId: number;
  effectiveDate: string;
  nextReviewDate: string;
  active: boolean;
  caseManager: IDictionary;
  goals: IClientTreatmentPlanGoal[];
  isArchived: boolean;
  isReadOnly: boolean;
  canEdit: boolean;
  type: IDictionary | null;
}

interface IVisitTreatmentPlanGoal {
  id?: number;
  text: string;
  objectives: IVisitTreatmentPlanObjective[];
}

export interface IVisitTreatmentPlanObjective {
  id: number;
  text: string;
  reviewed?: boolean;
}

export interface IVisitTreatmentPlan {
  id?: number;
  goals: IVisitTreatmentPlanGoal[];
}

export interface IClientAddressRequest {
  typeId: number;
  setAsPrimary: boolean;
  archivePrimary: boolean;
  shelterId?: number;
  isShelter?: boolean;
  address: IAddress;
}

export interface IClientPhoneRequest {
  number: string;
  typeId: number;
  setAsPrimary: boolean;
  archivePrimary: boolean;
}

export interface IClientSupportContanctRequest {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  relationshipId: number;
  address: IAddress;
}

interface ExtendedDictionary extends IDictionary {
  abbreviation: string;
}

type ISupportContactAddress = IAddressExtended & {
  state: ExtendedDictionary;
};

export interface IClientSupportContanctResponse {
  id: number;
  firstName: string;
  lastName: string;
  relationship: IDictionary;
  isArchived: boolean;
  phone: string;
  email: string;
  address: ISupportContactAddress;
}

interface ITeamMemberToNotify {
  id: number;
  firstName: string;
  lastName: string;
  photo: string;
  active: boolean;
  subRoles: IDictionary[];
}

export interface IClientCommonRequestParams {
  clinicId: string;
  teamId: string;
  clientId: string;
}
