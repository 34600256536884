import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AddIcon from '@material-ui/icons/Add';
import { ReactElement, Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useStoreActions, useStoreState } from '~/store/hooks';
import useRole from '~/store/user/hooks/useRole';
import useTeamConfirmProps from '../../hooks/useTeamConfirmProps';

import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Loader from '~/ui/components/common/Loader';
import { extractErrorMessage } from '~/utils/error/error';
import AssignUserModal from '../../reusable/AssignUserModal';
import Profile from '../../reusable/Profile';
import TeamMembers from '../TeamMembers';
import ProgramAssistant from '../ProgramAssistant';
import LocalAdmin from '../LocalAdmin';

import { IUserRole } from '~/types';
import { ACT_TEAM_MANAGEMENT, ADD_CLIENT_ALLOCATION, ADD_TEAM_MEMBER } from '~/ui/constants/paths';
import styles from './ViewActTeam.module.scss';

const ClientAllocation = lazy(() => import('../ClientAllocation'));

type IParams = {
  actTeamId: string;
};

type ILocationState = { pathname: string; state: { selectedTab: number } };

const ViewActTeam = (): ReactElement => {
  const { actTeamId } = useParams<IParams>();
  const { pathname, state: locationState }: ILocationState = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(locationState?.selectedTab || 0);
  const [teamMember, setTeamMember] = useState(null);
  const [assignModalVisible, setAssignModalVisible] = useState(false);

  const { clinic: userClinic } = useStoreState(state => state.user.current);
  const actTeam = useStoreState(state => state.actTeam.current);
  const pagination = useStoreState(state => state.actTeamMember.pagination);
  const { isArchived } = actTeam || {};

  const { resetPagination, onGetActTeamMembers } = useStoreActions(
    actions => actions.actTeamMember,
  );
  const filters = useStoreState(state => state.actTeamMember.filters);
  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetClinicActTeam = useStoreActions(actions => actions.actTeam.onGetClinicActTeam);
  const { showError } = useStoreActions(actions => actions.snackbar);

  const { isLocalAdmin, isProgramAssistant, isGlobalUser: isGlobalUserRole } = useRole();

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      resetPagination();
      const payload = { clinicId: String(userClinic.id), actTeamId };

      const promises = [onGetClinicActTeam(payload)];

      if (isLocalAdmin) {
        promises.push(onGetMyClinic());
      }

      await Promise.all(promises);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [
    actTeamId,
    isLocalAdmin,
    onGetClinicActTeam,
    onGetMyClinic,
    resetPagination,
    showError,
    userClinic.id,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const refetchUsers = async () => {
    try {
      await onGetActTeamMembers({
        clinicId: String(userClinic.id),
        actTeamId,
        params: { pageSize: pagination.pageSize, pageNumber: 1, ...filters },
      });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const { description, confirmText, method } = useTeamConfirmProps(teamMember);

  if (loading || !actTeam) return <Loader />;

  const breadcrumbItems = [
    { to: ACT_TEAM_MANAGEMENT, title: `${userClinic.name} Teams` },
    { to: pathname, title: actTeam?.name || '' },
  ];

  const onConfirm = async () => {
    try {
      await method();
      setTeamMember(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setTeamMember(false);
    }
  };

  const isSupervisor = isLocalAdmin || isProgramAssistant;

  return (
    <Suspense fallback={<Loader />}>
      <div>
        <div className={styles.header}>
          <h2>{actTeam?.name || ''}</h2>
          {!isArchived && (
            <div>
              {tab === 1 && !isGlobalUserRole && (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setAssignModalVisible(true)}
                    className={styles.assignUserButton}
                  >
                    Assign User
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => navigate(ADD_TEAM_MEMBER.replace(':actTeamId', actTeamId))}
                  >
                    Add Team Member
                  </Button>
                </>
              )}
              {tab === 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => navigate(ADD_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId))}
                >
                  Add Client Allocation
                </Button>
              )}
            </div>
          )}
        </div>
        <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />
        <div className={styles.tabsWrapper}>
          <Tabs
            value={tab}
            textColor="primary"
            indicatorColor="primary"
            onChange={(_e, val) => setTab(val)}
          >
            <Tab label="CLIENT ALLOCATIONS" />
            {!isGlobalUserRole && <Tab label="TEAM MEMBERS" />}
            {!isGlobalUserRole && <Tab label="TEAM PROFILE" />}
            {isSupervisor && <Tab label="LOCAL ADMINS" />}
            {isSupervisor && <Tab label="PROGRAM ASSISTANTS" />}
          </Tabs>
        </div>
        {tab === 4 && isSupervisor && <ProgramAssistant />}
        {tab === 3 && isSupervisor && <LocalAdmin />}
        {tab === 2 && !isGlobalUserRole && <Profile actTeam={actTeam} />}
        {tab === 1 && !isGlobalUserRole && (
          <TeamMembers
            clinicId={String(userClinic.id)}
            actTeamId={actTeamId}
            setTeamMember={setTeamMember}
            noActions={isArchived || isGlobalUserRole}
          />
        )}
        {tab === 0 && (
          <ClientAllocation
            clinicId={String(userClinic.id)}
            actTeamId={actTeamId}
            noActions={isArchived}
          />
        )}

        {teamMember && (
          <ConfirmModal
            onConfirm={onConfirm}
            confirmText={confirmText}
            description={description}
            onClose={() => setTeamMember(null)}
          />
        )}
        {assignModalVisible && (
          <AssignUserModal
            modalTitle={`Search user to assign to ${actTeam?.name}`}
            onClose={() => setAssignModalVisible(null)}
            role={IUserRole.ActTeamMember.replace(/\s/g, '')}
            refetchUsers={refetchUsers}
          />
        )}
      </div>
    </Suspense>
  );
};

export default ViewActTeam;
