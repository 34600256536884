import { useParams } from 'react-router-dom';
import api from '~/services/api';
import { IActTeamMember } from '~/services/api/actTeamMember/types';
import { useStoreActions, useStoreState } from '~/store/hooks';

import { IUserRole } from '~/types';
import { extractErrorMessage } from '~/utils/error/error';

const useTeamConfirmProps = (
  teamMember: IActTeamMember,
): {
  confirmText: string;
  description: string;
  method: () => void;
} => {
  const status = (teamMember || {})?.status as string;
  const { clinic: userClinic, id: userId } = useStoreState(state => state.user.current);
  const pagination = useStoreState(state => state.actTeamMember.pagination);
  const filters = useStoreState(state => state.actTeamMember.filters);

  const currentClinicRoles = (teamMember as IActTeamMember)?.roles?.filter(
    role => role.clinic.id === Number(userClinic.id),
  );

  const {
    onUnlockTeamMember,
    onActivateTeamMember,
    onDeactivateTeamMember,
    onArchiveTeamMember,
    onUnAssignTeamMember,
    onGetActTeamMembers,
    onDeactivateGlobalUser,
    onActivateGlobalUser,
    onArchiveGlobalUser,
    onResendInvitationGlobalUser,
    onUnlockGlobalUser,
  } = useStoreActions(actions => actions.actTeamMember);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const { actTeamId } = useParams<{
    actTeamId: string;
  }>();

  const isCurrentUser = teamMember?.id === userId;

  const isGlobalUser = !!currentClinicRoles?.find(role => role.name === IUserRole.GlobalUser);

  const payload = {
    clinicId: userClinic.id,
    actTeamId: Number(actTeamId),
    teamMemberId: teamMember?.id,
  };
  const globalUserPayload = {
    clinicId: userClinic.id,
    teamMemberId: teamMember?.id,
  };
  const commonParams = {
    pageSize: pagination.pageSize,
    pageNumber: pagination.pageNumber,
    ...filters,
  };
  if (status === 'Active')
    return {
      confirmText: 'Deactivate',
      description: 'Are you sure you want to deactivate this user?',
      method: () => {
        if (isCurrentUser) {
          showError("You can't deactivate yourself");
          return;
        }

        if (isGlobalUser) {
          onDeactivateGlobalUser(globalUserPayload);
        } else {
          onDeactivateTeamMember(payload);
        }
      },
    };

  if (status === 'Deactivated')
    return {
      confirmText: 'Activate',
      description: 'Are you sure you want to activate this user?',
      method: () =>
        isGlobalUser ? onActivateGlobalUser(globalUserPayload) : onActivateTeamMember(payload),
    };

  if (status === 'Locked')
    return {
      confirmText: 'Unlock',
      description: 'Are you sure you want to unlock this user?',
      method: () =>
        isGlobalUser ? onUnlockGlobalUser(globalUserPayload) : onUnlockTeamMember(payload),
    };

  if (status === 'Assigned')
    return {
      confirmText: 'Unassign',
      description: 'Are you sure you want to unassign this user?',
      method: async () => {
        try {
          if (isGlobalUser) {
            showError("Global user can't be unassigned");
          } else {
            await onUnAssignTeamMember(payload);
            await onGetActTeamMembers({
              clinicId: String(userClinic.id),
              actTeamId,
              params: commonParams,
            });
            showNotify({ message: `Team member successfully unassigned` });
          }
        } catch (e) {
          showError(extractErrorMessage(e));
        }
      },
    };
  if (status === 'VerifyingEmail')
    return {
      confirmText: 'Archive',
      description: 'Are you sure you want to archive this user?',
      method: async () => {
        try {
          if (isGlobalUser) {
            await onArchiveGlobalUser(globalUserPayload);
          } else {
            await onArchiveTeamMember(payload);
          }
          await onGetActTeamMembers({
            clinicId: String(userClinic.id),
            actTeamId,
            params: commonParams,
          });
          showNotify({ message: `Team member successfully archived` });
        } catch (e) {
          showError(extractErrorMessage(e));
        }
      },
    };

  return {
    confirmText: 'Resend',
    description: 'Are you sure you want to resend invitation?',
    method: async () => {
      try {
        if (isGlobalUser) {
          await onResendInvitationGlobalUser(globalUserPayload);
        } else {
          await api.actTeamMember.resendInvitation(userClinic.id, Number(actTeamId), teamMember.id);
        }
        showNotify({ message: `Invitation has been successfully sent` });
      } catch (e) {
        showError(extractErrorMessage(e));
      }
    },
  };
};

export default useTeamConfirmProps;
