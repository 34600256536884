import { ReactElement, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import ProgramAssistantTable from './Table';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import formatActTeamsOptions from '~/utils/formatActTeamsOptions';
import SimpleSelect from '~/ui/pages/MedicationLogistics/components/SimpleSelect';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import useProgramAssistantConfirmProps from './hooks/useProgramAssistantConfirmProps';

import styles from './ProgramAssistant.module.scss';

type IParams = {
  id: string;
};

const ProgramAssistant = (): ReactElement => {
  const { id: clinicId } = useParams<IParams>();
  const [programAssistant, setProgramAssistant] = useState(null);
  const [teamId, setTeamId] = useState(null);

  const programAssistantPagination = useStoreState(state => state.programAssistant.pagination);

  const { onGetProgramAssistants } = useStoreActions(actions => actions.programAssistant);

  const actTeamOptions = formatActTeamsOptions(programAssistant?.teams || []);

  const refetchUsers = useCallback(() => {
    onGetProgramAssistants({
      clinicId,
      params: {
        pageNumber: programAssistantPagination.pageNumber,
        pageSize: programAssistantPagination.pageSize,
      },
    });
  }, [
    clinicId,
    onGetProgramAssistants,
    programAssistantPagination.pageNumber,
    programAssistantPagination.pageSize,
  ]);

  const TeamSelect = (
    <div className={styles.selectContainer}>
      <span>Please select Team of this member to be unassigned.</span>
      <SimpleSelect
        label="Select Team"
        value={teamId}
        setValue={setTeamId}
        options={actTeamOptions}
      />
    </div>
  );

  const { confirmText, description, customContent, confirmDisabled, method } =
    useProgramAssistantConfirmProps({
      programAssistant,
      customContent: TeamSelect,
      teamId,
      refetchUsers,
    });

  const onConfirm = async () => {
    try {
      await method();
      setProgramAssistant(false);
    } catch (e) {
      setProgramAssistant(extractErrorMessage(e));
    } finally {
      setProgramAssistant(false);
    }
  };

  return (
    <>
      <ProgramAssistantTable clinicId={clinicId} setProgramAssistant={setProgramAssistant} />
      {programAssistant && (
        <ConfirmModal
          description={description}
          confirmText={confirmText}
          disabled={confirmDisabled}
          customContent={customContent}
          onClose={() => {
            setProgramAssistant(null);
            setTeamId(null);
          }}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default ProgramAssistant;
