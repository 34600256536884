import Grid from '@material-ui/core/Grid';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import useDiagnoses from './hooks/useDiagnoses';

import Button from '~/ui/components/common/Button';
import Loader from '~/ui/components/common/Loader';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';

import { IPrimaryDiagnosisInitialValues, IRequestInfo } from './types';

import styles from './Styles.module.scss';

interface IProps {
  setModalTitle: (v: any) => void;
  setDetails: (v: any) => void;
  requestInfo: IRequestInfo;
  defaultValues: IPrimaryDiagnosisInitialValues;
}

const EditPrimaryDiagnosis = ({
  setModalTitle,
  setDetails,
  requestInfo,
  defaultValues,
}: IProps): ReactElement => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const setCurrent = useStoreActions(actions => actions.clientDetails.setCurrent);
  const onGetClient = useStoreActions(actions => actions.client.onGetClient);

  const { loading, diagnosisOptions } = useDiagnoses();

  const onSubmit = async (vals: { id: number; note: string }) => {
    try {
      await api.clientDetails.updateClientPrimaryDiagnosis(requestInfo, vals);
      await onGetClient(requestInfo);

      setModalTitle(null);
      setDetails(null);
      showNotify({ message: 'Primary diagnosis successfully updated' });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  if (loading) return <Loader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Select
            isRelativeWindow
            name="id"
            options={diagnosisOptions}
            control={control}
            errors={errors}
            label="Select Primary Dx"
            hideSelectedOptions={false}
          />
        </Grid>
        <Grid item sm={12}>
          <Input
            name="note"
            register={register}
            label="Primary Dx Note"
            multiline
            errors={errors}
          />
        </Grid>
      </Grid>
      <div className={styles.buttonsWrapper}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setDetails(null);
            setModalTitle(null);
            setCurrent(null);
          }}
        >
          Cancel
        </Button>
        <Button color="primary" variant="contained" type="submit" className={styles.margin}>
          Update
        </Button>
      </div>
    </form>
  );
};

export default EditPrimaryDiagnosis;
