import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  fieldOnlyNumbersUpTo999,
  requiredField,
  zeroToOneNumbers,
} from '~/ui/constants/errorMessages';

const validationSchema = object().shape({
  systemPrompt: string().required(requiredField).typeError(requiredField),
  temperature: string()
    .required(requiredField)
    .matches(/^(0(\.\d+)?|1(\.0+)?)$/, {
      // ^(?:0(?:\.[0-9]{1,2})?|1(?:\.00?)?)$ - 2 decimals
      message: zeroToOneNumbers,
      excludeEmptyString: true,
    }),
  maxTokens: string()
    .required(requiredField)
    .matches(/^[1-9]\d{0,2}(?:,\d{1,3})?$/, {
      message: fieldOnlyNumbersUpTo999,
      excludeEmptyString: true,
    }),
});

export default yupResolver(validationSchema);
