import {
  IDeEscalationTechniques,
  IDiagnosis,
  IMedication,
  ITrigger,
} from '~/services/api/client/types';
import {
  IClientAllergy,
  IClientDeEscalationTechnique,
  IClientDiagnosis,
  IClientMedication,
  IClientTrigger,
  IClientTriggerType,
} from '~/services/api/clientDetails/types';
import { IHealthDetailGroup, IHealthDetailItem } from './types';

type HealthDetailsArray = Array<
  | IClientDeEscalationTechnique
  | IClientDiagnosis
  | IClientMedication
  | IClientTrigger
  | IClientAllergy
>;

const getProperty = (item: any) =>
  item.diagnosis ||
  item.trigger ||
  item.medication ||
  item.deEscalationTechnique ||
  item.allergy ||
  item.address;

export const formatHealthDetailsItem = (
  item: IDiagnosis | IMedication | ITrigger | IDeEscalationTechniques | IClientAllergy,
): IHealthDetailItem => {
  const { dose, injectable, startDate, endDate, outsideFacility } = item as IMedication;
  const { type } = item as ITrigger;

  return {
    name: getProperty(item)?.name,
    note: item.note,
    id: item.id,
    isArchived: item.isArchived,
    type: type as IClientTriggerType,
    dose,
    injectable,
    outsideFacility,
    startDate,
    endDate,
    status: (item as IDiagnosis).status,
    severityLevel: (item as IClientAllergy).severityLevel,
  };
};

export const groupByStatus = (arr: HealthDetailsArray): IHealthDetailGroup =>
  arr.reduce(
    (acc, item) => {
      const formatted = formatHealthDetailsItem(item);

      if (item.isArchived) {
        acc.archived.push(formatted);
      } else {
        acc.active.push(formatted);
      }

      return acc;
    },
    { active: [], archived: [] },
  );

export const sortByValue = (a: { value: string }, b: { value: string }): number =>
  a.value.localeCompare(b.value);

export const hasSeverAllergy = (allergies?: IClientAllergy[]): boolean =>
  (allergies || []).some(allergy => allergy.severityLevel?.highlighted && !allergy.isArchived);
