import {
  IClientSecondaryDiagnosisResponse,
  IClientDiagnosis,
} from '~/services/api/clientDetails/types';

const formatSecondaryDiagnosis = (
  currentDiagnosis: IClientSecondaryDiagnosisResponse,
): IClientDiagnosis => ({
  id: currentDiagnosis.id,
  note: currentDiagnosis.note,
  status: currentDiagnosis.status,
  diagnosis: { id: currentDiagnosis.diagnosis.id, name: currentDiagnosis.diagnosis.name },
  isArchived: false,
});

export default formatSecondaryDiagnosis;
