import { ReactElement, useCallback, useState } from 'react';

import ConfirmModal from '~/ui/components/common/ConfirmModal';
import LocalAdminTable from './Table';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

const LocalAdmin = (): ReactElement => {
  const [localAdmin, setLocalAdmin] = useState(null);

  const { onActivateLocalAdmin, onUnlockLocalAdmin } = useStoreActions(
    actions => actions.localAdmin,
  );
  const { clinic } = useStoreState(state => state.user.current);
  const clinicId = clinic?.id ? String(clinic.id) : undefined;

  const getConfirmProps = useCallback(
    (status: string): { confirmText: string; description: string; method: () => Promise<void> } => {
      const payload = { clinicId, localAdminId: localAdmin?.id };

      if (status === 'Deactivated')
        return {
          confirmText: 'Activate',
          description: 'Are you sure you want to activate this user?',
          method: () => onActivateLocalAdmin(payload),
        };

      return {
        // 2 statuses possible, Locked and Deactivated
        confirmText: 'Unlock',
        description: 'Are you sure you want to unlock this user?',
        method: () => onUnlockLocalAdmin(payload),
      };
    },
    [clinicId, localAdmin?.id, onUnlockLocalAdmin, onActivateLocalAdmin],
  );

  const { confirmText, description, method } = getConfirmProps(localAdmin?.status);

  const onConfirm = async () => {
    try {
      await method();
      setLocalAdmin(false);
    } catch (e) {
      setLocalAdmin(extractErrorMessage(e));
    } finally {
      setLocalAdmin(false);
    }
  };

  return (
    <>
      <LocalAdminTable setLocalAdmin={setLocalAdmin} />
      {localAdmin && (
        <ConfirmModal
          description={description}
          confirmText={confirmText}
          onClose={() => {
            setLocalAdmin(null);
          }}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default LocalAdmin;
