import { useCallback, useEffect, useState } from 'react';
import api from '~/services/api';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { useStoreActions } from '~/store/hooks';
import { IOption } from '~/types';
import { extractErrorMessage } from '~/utils/error/error';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';

interface IUseDiagnoses {
  loading: boolean;
  diagnosisOptions: IOption[];
  diagnosisStatusOptions: IOption[];
}

const useDiagnoses = (): IUseDiagnoses => {
  const [diagnosisOptions, setDiagnosisOptions] = useState<IOption[]>([] as IOption[]);
  const [diagnosisStatusOptions, setDiagnosisStatusOptions] = useState<IOption[]>([] as IOption[]);
  const [loading, setLoading] = useState(false);

  const { showError } = useStoreActions(actions => actions.snackbar);

  const onMount = useCallback(async () => {
    try {
      const secondaryDiagnosisDictionaries = await api.dictionaries
        .getAvailableTypeList(IDictionaryTypes.Diagnosis)
        .then(r => formatDictionaryOptions(r.data));
      setDiagnosisOptions(secondaryDiagnosisDictionaries);

      const diagnosisStatusDictionaries = await api.dictionaries
        .getAvailableTypeList(IDictionaryTypes.DiagnosisStatus)
        .then(r => formatDictionaryOptions(r.data));
      setDiagnosisStatusOptions(diagnosisStatusDictionaries);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return { loading, diagnosisOptions, diagnosisStatusOptions };
};

export default useDiagnoses;
