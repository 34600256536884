import { ReactElement, useCallback, useState } from 'react';

import ConfirmModal from '~/ui/components/common/ConfirmModal';
import ProgramAssistantTable from './Table';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

const ProgramAssistant = (): ReactElement => {
  const [programAssistant, setProgramAssistant] = useState(null);

  const { onActivateProgramAssistant, onUnlockProgramAssistant } = useStoreActions(
    actions => actions.programAssistant,
  );
  const { clinic } = useStoreState(state => state.user.current);
  const clinicId = clinic?.id ? String(clinic.id) : undefined;

  const getConfirmProps = useCallback(
    (status: string) => {
      const payload = { clinicId, programAssistantId: programAssistant?.id };

      if (status === 'Deactivated')
        return {
          confirmText: 'Activate',
          description: 'Are you sure you want to activate this user?',
          method: () => onActivateProgramAssistant(payload),
        };

      return {
        confirmText: 'Unlock',
        description: 'Are you sure you want to unlock this user?',
        method: () => onUnlockProgramAssistant(payload),
      };
    },
    [clinicId, programAssistant?.id, onActivateProgramAssistant, onUnlockProgramAssistant],
  );

  const { confirmText, description, method } = getConfirmProps(programAssistant?.status);

  const onConfirm = async () => {
    try {
      await method();
      setProgramAssistant(false);
    } catch (e) {
      setProgramAssistant(extractErrorMessage(e));
    } finally {
      setProgramAssistant(false);
    }
  };

  return (
    <>
      <ProgramAssistantTable setProgramAssistant={setProgramAssistant} />
      {programAssistant && (
        <ConfirmModal
          description={description}
          confirmText={confirmText}
          onClose={() => {
            setProgramAssistant(null);
          }}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default ProgramAssistant;
