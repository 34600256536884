import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import { IProgramAssistant } from '~/services/api/programAssistant/types';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

const useProgramAssistantConfirmProps = ({
  programAssistant,
  refetchUsers,
  teamId,
  customContent,
}: {
  programAssistant: IProgramAssistant | undefined;
  refetchUsers: () => void;
  teamId: number | null;
  customContent: ReactNode;
}): {
  confirmText: string;
  description: string;
  confirmDisabled?: boolean;
  customContent?: ReactNode;
  method: () => void;
} => {
  const { id: clinicId } = useParams<{ id: string }>();
  const { status } = programAssistant || {};

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);
  const {
    onDeactivateProgramAssistant,
    onActivateProgramAssistant,
    onUnlockProgramAssistant,
    onReInviteProgramAssistant,
    onUnAssignProgramAssistant,
    onArchiveProgramAssistant,
  } = useStoreActions(actions => actions.programAssistant);

  const payload = { clinicId, programAssistantId: programAssistant?.id };
  if (status === 'Active')
    return {
      confirmText: 'Deactivate',
      description: 'Are you sure you want to deactivate this user?',
      method: () => onDeactivateProgramAssistant(payload),
    };

  if (status === 'Deactivated')
    return {
      confirmText: 'Activate',
      description: 'Are you sure you want to activate this user?',
      method: () => onActivateProgramAssistant(payload),
    };

  if (status === 'Locked')
    return {
      confirmText: 'Unlock',
      description: 'Are you sure you want to unlock this user?',
      method: () => onUnlockProgramAssistant(payload),
    };
  if (status === 'VerifyingEmail')
    return {
      confirmText: 'Archive',
      description: 'Are you sure you want to archive this user?',
      method: async () => {
        try {
          await onArchiveProgramAssistant(payload);
          refetchUsers();
          showNotify({ message: `User successfully archived` });
        } catch (e) {
          showError(extractErrorMessage(e));
        }
      },
    };
  if (status === 'Assigned')
    return {
      confirmText: 'Unassign',
      description: `Are you sure you want to unassign ${programAssistant.firstName} ${programAssistant.lastName}?`,
      confirmDisabled: !teamId,
      customContent,
      method: async () => {
        try {
          await onUnAssignProgramAssistant({
            clinicId,
            programAssistantId: programAssistant?.id,
            teamId,
          });
          refetchUsers();
          showNotify({ message: `User successfully unassigned` });
        } catch (e) {
          showError(extractErrorMessage(e));
        }
      },
    };

  return {
    confirmText: 'Resend',
    description: 'Are you sure you want to resend invitation?',
    method: () =>
      onReInviteProgramAssistant({ clinicId, programAssistantId: programAssistant?.id }),
  };
};

export default useProgramAssistantConfirmProps;
