import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';
import { IThunkUpdateClientNames, IClientActions, IClientStore } from '../types';

const onUpdateClientNames: IThunkUpdateClientNames = thunk(
  async ({ setClient }: Actions<IClientActions>, payload, { getState }) => {
    const localState = getState() as IClientStore;

    const { requestInfo, requestPayload } = payload;

    await api.client.updateClientNames(requestInfo, requestPayload);

    setClient({ ...localState.current, ...requestPayload });
  },
);

export default onUpdateClientNames;
